<template>
  <div class="brands">
    <div
      v-for="(brandGroup, index) in items"
      :key="`brandGroup-${index}`"
      class="brands__col"
    >
      <p class="brands__title font font_bold font_uppercase font_m">
        {{ brandGroup.name }}
      </p>

      <Expander
        v-if="brandGroup.items.length > maxCount"
        :min-height="180"
        class="brands__expander"
      >
        <ul class="brands__list">
          <li v-for="brand in brandGroup.items" :key="brand.id" class="brands__item">
            <nuxt-link :to="brand.link || brand.to" class="brands__link">
              {{ brand.name }}
            </nuxt-link>
          </li>
        </ul>
      </Expander>

      <ul v-else class="brands__list">
        <li v-for="brand in brandGroup.items" :key="brand.id" class="brands__item">
          <nuxt-link :to="brand.link || brand.to" class="brands__link font font_m">
            {{ brand.name }}
          </nuxt-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Expander from '@/components/elements/Expander';

export default {
  name: 'Brands',
  components: {
    Expander,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      maxCount: 5,
    }
  },
};
</script>
