<template>
  <section class="brands-page">
    <Breadcrumbs :breadcrumbs="breadcrumbsList" class="brands-page__breadcrumbs" />

    <h1 class="title font font_title-l font_bold font_uppercase">
      Бренды
    </h1>

    <Brands v-if="brandList.length" :items="brandList" />
    <p v-else>
      Нет брендов
    </p>
  </section>
</template>

<script>
import { prepareBrand } from '@/plugins/helpers/catalog';
import Brands from '@/components/elements/Brands';
import Breadcrumbs from '@/components/elements/Breadcrumbs';

export default {
  name: 'BrandsPage',
  components: {
    Brands,
    Breadcrumbs,
  },
  async asyncData({ $api }) {
    try {
      function getBrandList(list) {
        const itemsMap = {};

        list.forEach(brand => {
          const item = prepareBrand(brand);

          const letter = item.name.slice(0, 1).toUpperCase();
          const map = itemsMap[letter];

          if (!map) itemsMap[letter] = {
            id: letter,
            name: letter,
            items: [item],
          };
          else map.items.push(item);
        })

        return sortBrandList(itemsMap);
      }

      function sortBrandList(brandsMap) {
        return Object.keys(brandsMap)
          .map(key => brandsMap[key])
          .sort((a, b) => a.name.charCodeAt(0) - b.name.charCodeAt(0))
      }

      const response = await $api.brands.getBrands() || [];
      const brandList = getBrandList(response)

      return {
        brandList: brandList || [],
        breadcrumbsList: [
          {
            name: 'Главная',
            url: '/',
          },
          {
            name: 'Бренды',
            url: '',
          },
        ],
      }

    } catch (error) {
      console.error(error);
    }
  },
};
</script>
